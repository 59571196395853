@use '/src/styles/vendors/material/material-colors' as colors;

* {
  box-sizing: border-box;
  outline: none;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

a:not(.mdc-button):not(.mat-mdc-menu-item) {
  color: #{colors.$primary-color} !important;

  // &:hover {
  //   color: colors.$app-primary-dark !important;
  // }
}

aup-sticky-banner {
  position: sticky;
  bottom: 0;
  z-index: 999999;
}
