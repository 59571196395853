.aup-select-with-buttons.mat-mdc-select-panel {
  padding-bottom: 113px;
}

.custom-select {
  width: 100px; /* Minimized width for the closed select */
}

::ng-deep .custom-select-panel {
  min-width: 200px !important; /* Full width for the options when the select is opened */
  width: 200px !important;
}
