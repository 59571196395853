@use '/src/styles/vendors/material/material-colors' as *;

table.mat-mdc-table {
  --mat-table-row-item-outline-color: #{$app-gray};
  --mat-table-background-color: #{$app-white};
  --mat-table-header-headline-size: 15px;
  tr {
    th {
      aup-filter {
        mat-form-field {
          .mat-mdc-form-field-infix {
            width: 100%;
          }
        }
      }
    }
  }
  .table-sticky thead th {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 2;
    box-shadow:
      inset 0 1px 0 red,
      inset 0 -2px 0 red;
  }
  tbody {
    tr {
      &.gray-background {
        background: #{$background-gray};
      }
      &.blue-border-left {
        td:first-of-type {
          border-left: 1px solid #{$primary-color};
        }
      }
    }
  }
  &.aup-table-border-bottom {
    border-bottom: 1px solid #{$app-gray};
  }
  .mat-sort-header-container .mat-sort-header-arrow {
    color: $app-blue;
  }
}

.aup-table-container {
  max-width: 100%;
  max-height: calc(
    100vh - var(--mat-toolbar-standard-height) - var(--mat-paginator-container-size) - 20px - 20px
  );
  overflow: auto;

  table {
    display: block;
    .mat-colum-status {
      width: 200px;
    }
    td.mat-mdc-cell {
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }

  .mat-mdc-table {
    border-collapse: separate;
  }

  .mat-mdc-table-sticky-border-elem-right {
    border-left: 1px solid #e0e0e0;
  }
}

.aup-mobile-table {
  tr {
    @apply border-t border-detail-list-border;
    &.last-row {
      @apply border-b-2;
    }
    td {
      @apply w-1/2 p-1;
      &:first-of-type {
        @apply font-bold uppercase;
      }
    }
  }
}

.aup-row {
  &:last-of-type {
    td {
      border-bottom: 1px solid transparent !important;
    }
  }
  td {
    border-top: 1px solid transparent;
    &:first-of-type {
      @apply border-l;
    }
    &:last-of-type {
      @apply border-r;
    }
  }
  &:hover {
    td {
      border-bottom: 1px solid #1474bd !important;
      border-top: 1px solid #1474bd !important;
      &:first-of-type {
        @apply border-l border-l-primary-interactive;
      }
      &:last-of-type {
        @apply border-r border-r-primary-interactive;
      }
    }
  }
}
