$primary-color: #1474bd;
$warn-color: #f5a623;

$app-white: rgb(255, 255, 255);
$app-black: rgb(0, 0, 0);
$title-color: #666;

$app-green: #09800f;
$app-orange: #e36306;
$app-blue: #1474bd;
$app-gray: #dddddd;
$app-background: #fafafa;
$background-gray: #f2f2f1;
$app-danger: #f0a9b2;
$app-dark-gray: #222;
$app-light-green: #9cd89f;
$app-red: #d0021b;

$notification-success-border: $app-green;
$notification-success-bg: #f3fef4;
$notification-info-border: #1183a7;
$notification-info-bg: #f3fbfe;
$notification-warning-border: #f5a623;
$notification-warning-bg: #fefaf3;
$notification-error-border: $app-red;
$notification-error-bg: #fff2f4;
$sticky-banner-shadow: #00000026;
