.aup-stepper-without-padding {
  .mat-horizontal-content-container {
    padding: 0;
  }
}
.aup-stepper-sticky-header {
  .mat-horizontal-stepper-wrapper {
    height: 100%;
    .mat-horizontal-content-container {
      flex-grow: 1;
      overflow: auto;
    }
  }
}
.aup-stepper-without-active-step-background {
  .mat-step-header:hover:not([aria-disabled]) {
    background-color: transparent !important;
    color: inherit;
  }
  .mat-step-header.cdk-program-focused {
    background-color: transparent !important;
    color: inherit;
  }
}
