@forward 'styles/common/common.scss';
@forward 'styles/vendors/vendors.scss';
@forward 'styles/components/components.scss';

#ext-viewport {
  //min-height: calc(100vh - 110px);
  min-height: 62.5rem;

  &.forced-height-5 {
    min-height: 46.5rem;
  }

  &.forced-height-10 {
    min-height: 62.5rem;
  }

  &.forced-height-20 {
    min-height: 93.5rem;
  }

  &.forced-height-50 {
    min-height: 189rem;
  }

  &.forced-height-100 {
    min-height: 343.5rem;
  }
}

.wrapper_fatturazione {
  [data-componentid*='ext-ricercaCommercialista-'] .x-panelheader-body-el {
    padding: 0 !important;

    .x-text-el {
      position: relative;
      text-align: left !important;
      margin-bottom: 9px;
      color: transparent;

      &:before {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        content: 'Ricerca Fatture';
        color: rgba(0, 0, 0, 0.87);
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
        font-family: 'Lato', sans-serif;
        letter-spacing: normal;
        margin: 0 0 16px;
      }
    }
  }

  .ricerca-commercialista {
    box-shadow: none !important;
  }

  .main-container {
    border: none !important;
    background: transparent !important;
  }

  .view-main-container-noborder .x-panelheader {
    background: transparent !important;
  }

  .aruba-panel .x-panel-body-el {
    background: transparent !important;
  }

  .x-textfield .x-input-wrap-el {
    min-height: 56px !important;
    max-height: 56px !important;
  }

  .trigger-cliccabile .x-body-el .x-input-wrap-el .x-after-input-el {
    height: 54px !important;
  }

  .x-trigger .x-icon-el {
    width: 10px !important;
    height: 10px !important;
  }

  .ricerca-commercialista .x-tabpanel .x-component.x-tab.x-active {
    border-top: 5px solid #1474bd !important;
    border-bottom: none !important;
    color: #1474bd !important;
  }

  .ricerca-commercialista .x-tabpanel .x-component.x-tab {
    color: #222 !important;
  }

  .x-tabbar-body-el {
    padding: 0 !important;
  }

  .ricerca-commercialista .x-tabpanel .x-tab {
    height: 52px !important;
  }

  .x-button-buttonDarkGrayShadow .x-inner-el {
    border-color: #1474bd !important;
    background-color: #1474bd !important;
    box-shadow: none !important;
  }

  .x-button-buttonWhiteShadowNoPadding .x-inner-el {
    box-shadow: none !important;
    background-color: transparent !important;
    border-color: transparent !important;
  }

  .x-button-buttonWhiteShadowNoPadding .x-arrow-el,
  .x-button-buttonWhiteShadowNoPadding .x-icon-el {
    filter: invert(33%) sepia(83%) saturate(953%) hue-rotate(176deg) brightness(93%) contrast(94%) !important;
  }

  .grid-search .x-component.x-button-buttonDarkGrayShadow,
  .grid-search .x-component.x-button-buttonWhiteShadowNoPadding,
  .inbox-panel-invoice-search .x-component.x-button-buttonDarkGrayShadow,
  .inbox-panel-invoice-search .x-component.x-button-buttonWhiteShadowNoPadding {
    margin: 8px 10px 0px 24px !important;
    width: 40px !important;
    height: 40px !important;
  }

  .x-icon-el.x-aru.aru-search-xsmall {
    height: 24px !important;
    width: 24px !important;
  }

  .arubagrid-filterHeader .x-checkcolumn-title-wrap-el,
  .arubagrid-filterHeader .x-title-el,
  .aruba-grid .x-headercontainer,
  .arubagrid-filterHeader .x-gridcolumn .x-header-el,
  .arubagrid-filterHeader .header-div-filter {
    background-color: transparent !important;
  }

  .x-gridcolumn .x-title-wrap-el {
    overflow: visible !important;
  }

  .fattura-inviata .arubagrid-filterHeader .x-headercontainer,
  .fattura-ricevuta .arubagrid-filterHeader .x-headercontainer {
    min-height: 149px !important;
  }

  .selectBulkToolbar .aruba-grid .x-header-el .x-gridcolumn-title-wrap-el .x-title-el {
    min-height: 68px !important;
  }

  .selectBulkToolbar .aruba-grid .x-header-el .x-gridcolumn-title-wrap-el.x-checkcolumn-title-wrap-el {
    margin-top: 14px !important;
  }

  .x-gridcolumn,
  .arubagrid-col-resize .fill-header-column {
    border-top: 1px solid #d8d8d8 !important;
  }

  .x-checkcell .x-checkbox-el:before,
  .x-checkcolumn .x-checkbox-el:before,
  .fa-check-square:before {
    font-size: 20px !important;
  }

  .aruba-comboboxcheck-listview .x-listitem > .x-body-el .x-tool-dock {
    padding: 0 16px !important;
  }

  .x-tool.x-start {
    margin: 0 8px 0 0 !important;
  }

  .x-tool .x-icon-el,
  .x-icon-el.x-aru.aru-advancedSearch-xsmall {
    width: 24px !important;
    height: 24px !important;
    opacity: 1 !important;
  }

  .aruba-comboboxcheck-listview .x-listitem {
    background-color: #fff !important;
  }

  .wrapper_fatturazione .aruba-comboboxcheck-listview .footer-container,
  .wrapper_fatturazione .aruba-comboboxcheck-listview .header-container {
    background-color: #fff !important;
  }

  .x-button-buttonGridWhiteMenu .x-inner-el {
    box-shadow: none !important;
    border: none !important;
    background-color: transparent !important;

    .x-text-el {
      display: none !important;
    }
  }

  .x-icon-el.svg-puntini {
    height: 24px !important;
    width: 24px !important;
    filter: invert(33%) sepia(75%) saturate(944%) hue-rotate(172deg) brightness(98%) contrast(93%) !important;
  }

  .x-menuitem .x-body-el {
    background-color: #fff !important;
  }

  .x-body-el {
    .ft-status-1,
    .ft-status-2,
    .ft-status-3,
    .ft-status-4,
    .ft-status-5,
    .ft-status-6,
    .ft-status-7,
    .ft-status-8,
    .ft-status-9,
    .ft-status-10 {
      border: none !important;
      color: #222 !important;
      font-weight: 700 !important;
      text-transform: capitalize !important;
      padding: 6px 12px !important;
      position: relative;
      padding-left: 22px !important;
      font-size: 12px !important;
    }
  }

  .x-body-el {
    .ft-status-1:before,
    .ft-status-2:before,
    .ft-status-3:before,
    .ft-status-4:before,
    .ft-status-5:before,
    .ft-status-6:before,
    .ft-status-7:before,
    .ft-status-8:before,
    .ft-status-9:before,
    .ft-status-10:before {
      position: absolute;
      content: '';
      width: 8px;
      height: 8px;
      background: #09800f;
      border-radius: 50%;
      left: 8px;
      top: 50%;
      transform: translateY(-50%);
    }

    .ft-status-2:before,
    .ft-status-4:before,
    .ft-status-6:before,
    .ft-status-9:before {
      background: #d0021b;
    }

    .ft-status-10:before {
      background: #f5a623;
    }
  }

  .x-combobox.x-textfield .x-input-el {
    font-style: italic !important;
    cursor: pointer !important;
  }

  .ricerca-commercialista .x-tabpanel .x-container.x-toolbar.x-tabbar {
    background-color: #f9f9f9 !important;
  }

  .fattura-inviata .inbox-panel-invoice-search .x-body-el.x-panel-body-el,
  .fattura-ricevuta .inbox-panel-invoice-search .x-body-el.x-panel-body-el {
    padding-top: 8px !important;
    padding-left: 16px !important;
  }

  .inbox-segmented-button {
    height: 54px !important;
  }

  [data-reference='arubabuttongridpersonalization-btnGridPersonalization'] {
    margin-top: 10px !important;

    .x-inner-el {
      border: none !important;

      &:after {
        display: none !important;
      }
    }
  }

  [data-reference='null-list'] {
    .x-button-buttonWhiteShadow {
      width: calc(100% - 28px) !important;
    }

    .x-layout-box.x-horizontal {
      flex-direction: column !important;
      padding-top: 6px;
      padding-bottom: 6px;
      background: #fff !important;
    }
  }

  .aruba-comboboxcheck-listview .x-component.x-listitem.x-selected {
    background-color: #fff !important;
  }

  .aruba-comboboxcheck-listview .header-container {
    height: 96px !important;
    background: #fff !important;
  }

  .x-component.x-button-buttonWhiteShadow,
  .x-component.x-button-buttonDarkGrayShadow {
    height: auto !important;

    &.x-has-text .x-inner-el {
      padding: 0.5rem 1rem !important;
    }

    .x-inner-el {
      box-shadow: none !important;
    }
  }

  [data-reference='arubabutton-pickerSelectAll'] {
    .x-inner-el {
      border-color: #1474bd !important;
      border-width: 2px !important;
    }

    .x-body-el {
      color: #1474bd !important;
    }
  }

  .aruba-comboboxcheck-listview.aruba-comboboxcheck-listview-header .x-scroller.x-list-outer-ct {
    margin-top: 96px !important;
  }

  .x-list.x-floated {
    border: 1px solid #ddd !important;
    border-radius: 4px !important;
    box-shadow: 0px 4px 8px 0px rgba(34, 34, 34, 0.16) !important;
  }

  // [data-reference='arubalockedgrid-grid'] .x-layout-box-item {
  //   flex-shrink: inherit !important;
  // }

  [data-reference='arubalockedgrid-grid'] .x-button-buttonWhiteShadow {
    height: 44px !important;
    padding-top: 11px !important;
  }

  [data-reference='sentinvoicedetailpanel-detailPanel'],
  [data-reference='receivedinvoicedetailpanel-detailPanel'] {
    .x-button-buttonWhiteShadow {
      height: 40px !important;

      .x-inner-el {
        border: 2px solid #1474bd !important;
      }

      .x-text-el {
        color: #1474bd !important;
        text-transform: capitalize !important;
      }
    }
  }

  [data-reference='navigationgridpanel-navigationGridPanel'] .x-toolbar.gray-background {
    background-color: #fff !important;

    .x-body-el {
      align-items: center !important;
    }
  }

  .navigation-grid-panel .navigation-grid .x-component.x-selected {
    border: none !important;
  }

  .navigation-grid-panel .x-component.navigation-grid,
  .navigation-grid-panel .navigation-grid .x-component.x-listitem {
    background-color: #fff !important;
  }

  .aruba-grid .x-listitem.x-component.x-selected {
    background-color: #f3f9fe !important;
  }

  .sln-detail-panel .x-panelheader .x-body-el {
    padding-left: 8px !important;

    .x-text-el {
      display: none;
    }
  }

  [data-reference='tool-btnCloseDetail'] .x-icon-el {
    width: 16px !important;
    height: 16px !important;
    margin-right: 6px !important;
  }

  .sln-detail-panel {
    .gray-background,
    .x-component.x-panelheader {
      background-color: transparent !important;
    }
  }

  .arubagrid-col-resize .standard-header-column {
    background: #fff !important;
  }

  [data-reference='arubagridcolumncheck-colCheck'] {
    background: #fff !important;
  }

  .x-managed-borders > .x-docked-bottom {
    height: 72px !important;
  }

  [data-reference='arubacombobox-fieldPageSize'] > .x-label-el,
  .x-pagingtoolbar .pagingtoolbar-button-container .x-component > .x-label-el {
    display: flex !important;
    align-items: center !important;
  }

  [data-reference='fatturainviatainboxgridpanel-mainGridPanel'] {
    background-color: #fff !important;

    & > .x-body-el > .x-layout-vbox-item > .x-align-stretch > .x-layout-vbox-item {
      margin-bottom: 0.5rem !important;
    }
  }

  [data-reference='arubatoolbar-toolbarAction'] {
    background-color: #fff !important;
    border-bottom: 1px solid #d8d8d8 !important;
    border-bottom-width: 1px !important;
  }

  // [data-reference='fatturainviatainboxgridpanel-mainGridPanel'] {
  //   .x-layout-box-item .x-container-body-el {
  //     gap: 6px;
  //   }
  // }

  .arubagrid-col-resize .x-component.standard-header-column.x-noborder-trbl {
    border-top: none !important;
  }

  .arubagrid-filterHeader .header-div-filter .x-datetrigger.x-trigger {
    width: 26px !important;

    .x-icon-el.x-font-icon {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24px' height='24px' fill='currentColor' focusable='false' aria-hidden='true' class='mat-datepicker-toggle-default-icon ng-star-inserted'%3E%3Cpath d='M19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11zM7 10h5v5H7z'%3E%3C/path%3E%3C/svg%3E") !important;
      width: 24px !important;
      height: 24px !important;
      filter: invert(63%) sepia(0%) saturate(1328%) hue-rotate(154deg) brightness(77%) contrast(93%);
    }
  }

  [data-reference='arubagridcolumnfiltergrid-colAction'] {
    width: 80px !important;
  }

  .x-float-wrap > .aruba-panel > .x-body-wrap-el > .x-layout-box > .x-container > .x-body-el {
    background-color: #fff;
  }

  // .aruba-chipview .x-chip {
  //   height: 24px !important;

  //   .x-body-el .x-text-el {
  //     font-size: 12px !important;
  //     padding: 6px !important;
  //     font-weight: 700 !important;
  //   }
  // }

  .aruba-chipview .x-chipview-body-el {
    flex-wrap: nowrap !important;
    // gap: 4px !important;
  }

  .x-button-scroll-to-top .x-inner-el {
    background-color: #fff !important;
  }

  [data-reference='arubabutton-actionsDetailButton'] {
    .x-body-el {
      flex-direction: row-reverse !important;
      gap: 0.5rem !important;

      .x-text-el {
        text-transform: uppercase !important;
      }
    }
  }

  .x-field.x-error-target-under > * > .x-error-el {
    display: none !important;
  }

  .fa-check-square:before {
    color: #1474bd !important;
  }

  [data-reference='navigationview-mainContainer'] > .x-dock > .x-body-el > .x-container > .x-body-el {
    margin: -15px -15px -30px -15px !important;
  }

  [data-reference='arubapanelclickevent-asyncDownloadPanel'] {
    .x-tool:has(> .svg-close-download) {
      display: block !important;
    }

    .x-tool .svg-close-download {
      width: 16px !important;
    }
  }

  .loader {
    color: #3f3f3f !important;
  }
}

.wrapper_fatturazione.x-drag-dragging {
  z-index: 9999999 !important;
}
