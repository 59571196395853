label {
  font-size: 14px;
}

mat-form-field {
  input {
    font-size: 16px;
  }
  mat-select {
    font-size: 16px;
  }
}
