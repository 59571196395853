@use '/src/styles/vendors/material/material-colors' as *;

mat-snack-bar-container.aup-toast-message {
  &-error {
    .mdc-snackbar__surface {
      background-color: #{$app-danger};
      .mat-mdc-snack-bar-label {
        aup-toast-message {
          color: #{$app-dark-gray};
        }
      }
    }
  }
  &-success {
    .mdc-snackbar__surface {
      background-color: #{$app-light-green};
      .mat-mdc-snack-bar-label {
        aup-toast-message {
          color: #{$app-dark-gray};
        }
      }
    }
  }
}

mat-snack-bar-container.mat-mdc-snack-bar-container {
  margin-top: 70px;
  .mdc-snackbar__surface {
    min-width: 7rem;
    .mat-mdc-snack-bar-label {
      padding: 0;
      aup-toast-message {
        mat-icon {
          width: 1.5rem;
          height: 1.5rem;
          svg {
            width: 1.5rem;
            height: 1.5rem;
          }
        }
      }
    }
  }
}
