.aup-paginator {
  &-space-between {
    width: 100%;
    mat-paginator {
      width: 100%;
      .mat-mdc-paginator-container {
        justify-content: space-between;
      }
    }
  }
}
