@use './material-colors' as colors;

:host {
  --mdc-elevated-card-container-elevation: gba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(34, 34, 34, 0.16) 0px 0px 8px 0px;
}

.mat-mdc-form-field-error-wrapper {
  padding-left: 0 !important;
}
mat-select {
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
}
.mat-mdc-select-panel {
  --mat-select-panel-background-color: #{colors.$app-white};
  --mat-option-focus-state-layer-color: #0000000a;
  --mat-option-hover-state-layer-color: #0000000a;
}

.mat-mdc-menu-panel {
  --mat-menu-container-color: #{colors.$app-white};
}

mat-divider {
  --mat-divider-color: #{colors.$app-gray};
}

mat-sidenav-container {
  --mat-sidenav-content-background-color: #{colors.$app-background};
  --mat-sidenav-container-background-color: #{colors.$app-white};
  --mat-sidenav-container-shape: 0;
  --mat-sidenav-container-divider-color: #{colors.$app-gray};
  --mat-sidenav-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-container-width: auto;
  --mat-sidenav-content-text-color: rgba(0, 0, 0, 0.87);
}

mat-card {
  --mdc-elevated-card-container-color: #{colors.$app-white};
  --mdc-elevated-card-container-shape: 4px;
}

mat-dialog-container {
  --mdc-dialog-container-shape: 4px;
  --mat-dialog-actions-alignment: flex-start;
}

mat-datepicker-content {
  --mat-datepicker-calendar-container-shape: 0;
  --mat-datepicker-calendar-container-background-color: #{colors.$app-white};
  &.mat-datepicker-content {
    box-shadow:
      0px 2px 4px -1px rgba(0, 0, 0, 0.2),
      0px 4px 5px 0px rgba(0, 0, 0, 0.14),
      0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  }
}
mat-paginator {
  --mat-paginator-container-background-color: #{colors.$app-white};
}

mat-form-field {
  --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, 0.38);
}
