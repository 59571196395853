@use '/src/styles/vendors/material/material-colors' as *;

.aup-tabs {
  .mat-mdc-tab {
    --mat-tab-header-label-text-font: 'Lato';
    --mat-tab-header-label-text-weight: 700;
    --mat-tab-header-active-label-text-color: #{$primary-color};
    --mat-tab-header-active-focus-label-text-color: #{$primary-color};
    --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
    --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
    --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
    //border-bottom: 4px solid $app-blue;
    &:not(.mdc-tab--active) {
      border-bottom: 4px solid #{$app-gray};
    }
    &.mdc-tab--active .mdc-tab-indicator__content--underline {
      border-top-width: 4px;
    }
  }
  &-short-tabs {
    .mat-mdc-tab {
      flex-grow: 0 !important;
    }
  }
}
