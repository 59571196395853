@use '@angular/material' as mat;
@use 'material-colors' as *;

// NOTE: It is typography definition of material2, i left it for reference
// $app-typography-config: mat.m2-define-typography-config(
//   $font-family: 'Lato',
//   $headline-1: mat.m2-define-typography-level(
//       112px,
//       112px,
//       300,
//       $font-family: 'Lato',
//       $letter-spacing: normal
//     ),
//   $headline-2: mat.m2-define-typography-level(56px, 56px, 700, $font-family: 'Lato', $letter-spacing: normal),
//   $headline-3: mat.m2-define-typography-level(45px, 48px, 700, $font-family: 'Lato', $letter-spacing: normal),
//   $headline-4: mat.m2-define-typography-level(34px, 40px, 700, $font-family: 'Lato', $letter-spacing: normal),
//   $headline-5: mat.m2-define-typography-level(24px, 32px, 700, $font-family: 'Lato', $letter-spacing: normal),
//   $headline-6: mat.m2-define-typography-level(24px, 28px, 400, $font-family: 'Lato', $letter-spacing: normal),
//   $subtitle-1: mat.m2-define-typography-level(20px, 28px, 700, $font-family: 'Lato', $letter-spacing: normal),
//   $subtitle-2: mat.m2-define-typography-level(15px, 24px, 400, $font-family: 'Arial', $letter-spacing: normal),
//   $body-1: mat.m2-define-typography-level(16px, 24px, 500, $font-family: 'Arial', $letter-spacing: normal),
//   $body-2: mat.m2-define-typography-level(14px, 18px, 400, $font-family: 'Arial', $letter-spacing: normal),
//   $caption: mat.m2-define-typography-level(12px, 20px, 400, $font-family: 'Lato', $letter-spacing: normal),
//   $button: mat.m2-define-typography-level(14px, 16px, 700, $font-family: 'Lato', $letter-spacing: normal),
// );

body,
p {
  font-family: 'Arial', sans-serif;
  font-size: 14px;
  line-height: 18px;
}

:root {
  h1 {
    font-family: 'Lato';
    font-size: 24px;
    line-height: 32px;
    letter-spacing: normal;
    font-weight: 700;
    margin-bottom: 16px;
  }

  h2 {
    font-family: 'Lato';
    font-size: 24px;
    line-height: 28px;
    letter-spacing: normal;
    font-weight: 400;
    margin-bottom: 16px;
  }

  h3 {
    font-family: 'Lato';
    font-size: 20px;
    line-height: 28px;
    letter-spacing: normal;
    font-weight: 700;
    margin-bottom: 0px !important;
  }

  h4 {
    font-family: 'Arial';
    font-size: 15px;
    line-height: 24px;
    letter-spacing: normal;
    font-weight: 400;
    &.without-margin {
      margin-bottom: 0;
    }
  }

  .body-1 {
    font-family: 'Arial';
    font-size: 16px;
    line-height: 24px;
    letter-spacing: normal;
    font-weight: 500;
  }

  .body-2,
  .mat-body-2 {
    font-family: 'Arial';
    font-size: 14px;
    line-height: 18px;
    letter-spacing: normal;
    font-weight: 400;
  }

  .mat-caption {
    font-family: 'Lato';
    font-size: 12px;
    line-height: 20px;
    letter-spacing: normal;
    font-weight: 400;
  }

  .mat-subtitle-2 {
    font-family: 'Arial';
    font-size: 15px;
    line-height: 24px;
    letter-spacing: normal;
    font-weight: 400;
  }

  h3,
  p {
    margin-bottom: 0px !important;
  }

  .text-gray {
    color: #{$title-color};
  }

  .text-blue {
    color: #{$primary-color};
  }

  label {
    font-family: 'Lato';
  }
  .mdc-form-field {
    label {
      font-family: Arial;
    }
  }
}
