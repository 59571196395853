@use '/src/styles/vendors/material/material-colors' as colors;

button.aup-action-button {
  border: 2px solid #{colors.$app-blue};
  padding: 0.5rem 1rem;
  height: 2.5rem !important;
  mat-icon {
    svg {
      path {
        fill: #{colors.$app-blue};
      }
    }
  }
}

button.mdc-button,
a.mdc-button {
  border-radius: 2px !important;
  font-weight: 700;
  font-family: 'Lato';
  line-height: 16px;
}

button.mdc-button.aup-btn-stroked-primary {
  border: 2px solid #{colors.$app-blue};
  background-color: #{colors.$app-white};
  color: #{colors.$app-blue};
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
}

a.mdc-button.mat-mdc-button.mat-mdc-button-base {
  --mdc-text-button-label-text-weight: 700;
}
