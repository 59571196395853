@use '/src/styles/vendors/material/material-colors' as colors;

.aup-filter-chip {
  --mdc-chip-container-shape-radius: 16px;
  --mdc-chip-outline-color: #{colors.$primary-color};
  span.mat-mdc-chip-action-label {
    color: #{colors.$primary-color} !important;
    font-weight: 700;
    font-size: 0.75rem;
  }
}
